<template>
  <div>
    <b-row>
      <b-col md="12">
      </b-col>
       <b-col class="resource-loading-filtro" md="12" v-if="!isEmpty(listagemFiltros.workcenter) || !isEmpty(listagemFiltros.resource) || (listagemFiltros.date.dataInicio)">
        <button type="button" @click="clearFilters" class="limpar-filtros-btn d-none d-sm-block">{{$t('ResourceLoading.Clean')}}</button>
        <div class="filtros-area">
          <div class="filtro-item" v-if="listagemFiltros.date && listagemFiltros.date.dataInicio && listagemFiltros.date.dataFim" >
            {{ getFormattedDate() }}
            <imgDeleteIcon @click="deleteFilteredItem('date')" />
          </div>
          <div class="filtro-item" v-for="workcenter in listagemFiltros.workcenter" :key="'workcenter-filter-item' + workcenter.id">
            {{workcenter.name}}<imgDeleteIcon @click="deleteFilteredItem('workcenter', workcenter.id)" />
          </div>
          <div class="filtro-item"  v-for="resource in listagemFiltros.resource" :key="'resource-filter-item' + resource.id">
            {{resource.name}}<imgDeleteIcon @click="deleteFilteredItem('resource', resource.id)" />
          </div>
        </div>
      </b-col> 
    </b-row>
    <b-row>
      <b-col class="col-12 col-sm-6 col-xl-3">
        <b-card class="top-card-oee">
          <div class="infos-card">
            <div class="header-card">
              <span class="title">{{$t('OeeDashboard.OverallOEE')}}</span>
              <imgTooltipIcon id="tooltip-oee-global"/>
              <b-tooltip
                custom-class="tooltip-oee"
                target="tooltip-oee-global"
                triggers="hover"
                positioning="bottom"
                placement="bottom"
              >
              <div class="tooltip-top-card-oee">
                  <div class="title-tooltip">
                    <p>
                      {{$t('OeeDashboard.OEETooltipTitle')}}
                    </p>
                  </div>
                  <div class="info-tooltip">
                    <p class="m-0 w-100"> 
                      {{$t('OeeDashboard.OEETooltip')}}
                    </p>
                  </div>
                </div>
              </b-tooltip>
            </div>
            <div class="percent-graph oee">
              <vc-donut 
                   :key="keychart"
                    :size="180"
                    unit="px" 
                    :thickness="25"
                    :foreground="`${parseFloat(this.oeeGlobal.oee) == 100 ? '#ffff' :'#F4EEE8'}`"
                    :sections="[{ value: this.oeeGlobal.oee !== null ? parseFloat(this.oeeGlobal.oee)/2 : 0, color: '#FF8D2E'  }]"
                    :start-angle="-90"
                    >{{ this.oeeGlobal.oee !== null ? parseFloat(this.oeeGlobal.oee).toFixed(0) : '0' }}%
              </vc-donut>
              
            </div>
          </div>
        </b-card> 
      </b-col>
      <b-col class="col-12 col-sm-6 col-xl-3">
        <b-card class="top-card-oee">
          <div class="infos-card">
              <div class="header-card">
                  <div class="container-card-title">
                    <span class="title">{{$t('OeeDashboard.Availability')}}</span>
                    <imgTooltipIcon id="tooltip-oee-availability"/>
                    <b-tooltip
                      custom-class="tooltip-oee"
                      target="tooltip-oee-availability"
                      triggers="hover"
                      positioning="bottom"
                      placement="bottom"
                    >
                     <div class="tooltip-top-card-oee">
                        <div class="title-tooltip">
                          <p>
                            {{$t('OeeDashboard.AvailabilityTooltipTitle1')}}<br class="d-block d-sm-none">{{$t('OeeDashboard.AvailabilityTooltipTitle2')}}
                          </p>
                          
                        </div>
                        <div class="info-tooltip">
                          <p v-html="$t('OeeDashboard.TimeOperationTooltip')">
                          </p>
                          <p v-html="$t('OeeDashboard.AvailableTimeTooltip')">
                          </p>
                        </div>
                     </div>
                    </b-tooltip>
                  </div>
                  <span class="percent">{{ this.oeeGlobal.available_component !== null ? parseFloat(this.oeeGlobal.available_component).toFixed(0) : '0' }}%</span>
              </div>
              <div class="color-bar">
                <div
                    class="bar-active"
                    :style="`width:${ this.oeeGlobal.available_component !== null ? parseFloat(this.oeeGlobal.available_component).toFixed(2) : 0}%;`"
                />
                <div
                    class="bar"
                    :style="`width:${ this.oeeGlobal.available_component !== null ? 100 - parseFloat(this.oeeGlobal.available_component).toFixed(2) : 100}%;`"
                />
              </div>
              <div class="indicators">
                <p class="tilte-indicator">{{$t('OeeDashboard.TimeOperation')}}</p>
                <span class="indicator">{{ this.oeeGlobal.production_time !== null ? parseFloat(this.oeeGlobal.production_time).toFixed(1) : '0' }} horas</span>
              </div>
              <div class="indicators">
                <p class="tilte-indicator">{{$t('OeeDashboard.AvailableTime')}}</p>
                <span class="indicator">{{ this.oeeGlobal.available !== null ? parseFloat(this.oeeGlobal.available).toFixed(1) : '0' }} horas</span>
              </div>
          </div>
        </b-card> 
      </b-col>
      <b-col class="col-12 col-sm-6 col-xl-3">
        <b-card class="top-card-oee">
          <div class="infos-card ">
              <div class="header-card">
                <div class="container-card-title">
                  <span class="title">{{$t('OeeDashboard.Performance')}}</span>
                    <imgTooltipIcon id="tooltip-oee-performance"/>
                    <b-tooltip
                      custom-class="tooltip-oee"
                      target="tooltip-oee-performance"
                      triggers="hover"
                      positioning="bottom"
                      placement="bottom"
                    >
                      <div class="tooltip-top-card-oee" >
                        <div class="title-tooltip">
                          <p class="title-performance">
                            {{$t('OeeDashboard.PerformanceTooltipTitle1')}}<br class="d-block d-sm-none">{{$t('OeeDashboard.PerformanceTooltipTitle2')}}
                          </p>
                        </div>
                        <div class="d-flex flex-column info-tooltip">
                          <p class="order-2 order-sm-1 pr-sm-5" v-html="$t('OeeDashboard.RealProductionTooltip')">
                          </p>
                          <p class="order-1 order-sm-2" v-html="$t('OeeDashboard.ScheduledProductionTooltip')">
                          </p>
                        </div>
                    </div>
                    </b-tooltip>
                </div>
                <span class="percent">{{ this.oeeGlobal.performance_component !== null ? parseFloat(this.oeeGlobal.performance_component).toFixed(0) : '0' }}%</span>
              </div>
              <div class="color-bar">
                <div
                    class="bar-active"
                    :style="`width:${ this.oeeGlobal.performance_component !== null ? parseFloat(this.oeeGlobal.performance_component).toFixed(2) : 0}%;`"
                />
                <div
                    class="bar"
                    :style="`width:${this.oeeGlobal.performance_component !== null ? 100 - parseFloat(this.oeeGlobal.performance_component).toFixed(2) : 100}%;`"
                />
              </div>
              <div class="indicators">
                <p class="tilte-indicator">{{$t('OeeDashboard.RealProduction')}}</p>
                <span class="indicator">{{ this.oeeGlobal.real_production !== null ? this.oeeGlobal.real_production : '0' }}</span>
              </div>
              <div class="indicators">
                <p class="tilte-indicator">{{$t('OeeDashboard.ScheduledProduction')}}</p>
                <span class="indicator">{{ this.oeeGlobal.capacicty_production !== null ? this.oeeGlobal.capacicty_production : '0' }}</span>
              </div>
          </div>
        </b-card> 
      </b-col>
      <b-col class="col-12 col-sm-6 col-xl-3">
        <b-card class="top-card-oee">
          <div class="infos-card">
              <div class="header-card">
                <div class="container-card-title">
                  <span class="title">{{$t('OeeDashboard.Quality')}}</span>
                  <imgTooltipIcon id="tooltip-oee-quality"/>
                  <b-tooltip
                    custom-class="tooltip-oee"
                    target="tooltip-oee-quality"
                    triggers="hover"
                    positioning="bottom"
                    placement="bottom"
                  >
                    <div class="tooltip-top-card-oee">
                      <div class="title-tooltip quality">
                        <p>
                          {{$t('OeeDashboard.QualityTooltipTitle1')}}<br class="d-block d-sm-none">{{$t('OeeDashboard.QualityTooltipTitle2')}}
                        </p>
                      
                      </div>
                      <div class="info-tooltip quality">
                        <p class="pr-5" v-html="$t('OeeDashboard.ApprovedProductionTooltip')">
                        </p>
                        <p class="pr-5 pr-sm-0" v-html="$t('OeeDashboard.RealProductionTooltip')">
                        </p>                  
                      </div>
                    </div>
                  </b-tooltip>
                </div>
                <span class="percent">{{ this.oeeGlobal.quality_component !== null ? parseFloat(this.oeeGlobal.quality_component).toFixed(0) : '0' }}%</span>
              </div>
              <div class="color-bar">
                <div
                    class="bar-active"
                    :style="`width:${ this.oeeGlobal.quality_component !== null ? parseFloat(this.oeeGlobal.quality_component).toFixed(2) : 0 }%;`"
                />
                <div
                    class="bar"
                    :style="`width:${ this.oeeGlobal.quality_component !== null ? 100 - parseFloat(this.oeeGlobal.quality_component) : 100}%;`"
                />
              </div>
              <div class="indicators">
                <p class="tilte-indicator">{{$t('OeeDashboard.ApprovedProduction')}}</p>
                <span class="indicator">{{ this.oeeGlobal.approved_quantity !== null ? this.oeeGlobal.approved_quantity : '0' }}</span>
              </div>
              <div class="indicators">
                <p class="tilte-indicator">{{$t('OeeDashboard.RealProduction')}}</p>
                <span class="indicator">{{ this.oeeGlobal.real_production !== null ? this.oeeGlobal.real_production : '0' }}</span>
              </div>
          </div>
        </b-card> 
      </b-col>
    </b-row>
    <b-row>
      <b-col class="col-12 col-lg-8">
        <b-card class="card-oee-dia">
           <p class="title">{{$t('OeeDashboard.OEEbyDay')}}</p>
          <div class="">
               <ag-charts-vue :options="options" :key="`control-chart-${keyControlRenderChart}`"></ag-charts-vue>
          </div>
        </b-card> 
      </b-col>
      <b-col class="col-12 col-lg-4">
        <b-card class="card-oee-recursos">
          <p class="title">{{$t('OeeDashboard.OEEbyResource')}}</p>
          <div class="infos-card">
              <div v-for="(recurso, index) in resourcesList" :key="index" class="resource-indicator">
                  <div class="title-percent">
                      <span class="resource-name">{{recurso.resource_name}}</span>
                      <span class="resource-percent">{{ recurso.oee_resource !== null ? parseFloat(recurso.oee_resource).toFixed(0) : '0' }}%</span>
                  </div>
                  <div class="color-bar">
                    <div
                        class="bar-active"
                        :style="`width:${ recurso.oee_resource !== null ? parseFloat(recurso.oee_resource).toFixed(2) : 0}%;`"
                    />
                    <div
                        class="bar"
                        :style="`width:${ recurso.oee_resource !== null ? 100 - parseFloat(recurso.oee_resource) : 100}%;`"
                    />
                  </div>
              </div>
          </div>
        </b-card> 
      </b-col>
    </b-row>
  </div>
</template>

<script>
import imgTooltipIcon from "@/assets/images/pages/tooltip-icon.svg";
import imgDeleteIcon from '@/assets/images/icons/delete-icon.svg';
import FilterDays from '@/@core/components/filter-days/FilterDays.vue'
import { AgChartsVue } from 'ag-charts-vue';
import moment from "moment";
import { mapMutations } from "vuex";

import { 
  BCard, 
  BCardGroup,
  BCardText, 
  BLink,
  BDropdown,
  BDropdownItem,
  BRow,
  BCol,
  BBadge,
  BButton,
  BFormGroup,
  BTooltip,
  } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardGroup,
    BCardText,
    BLink,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BBadge,
    BButton,
    BFormGroup,
    imgTooltipIcon,
    imgDeleteIcon,
    BTooltip,
    FilterDays,
    AgChartsVue
  },
  data() {
    return {
     keyControlRenderChart: 0,
     listagemFiltros: {
        workcenter: [],
        resource: [],
        date: {
          dataInicio: '',
          dataFim: ''
        }
      },
      urlResourcesLoading:'',
      currentSite: '',
      oeeGlobal: null,
      resourcesList: [],
      dadosGrafico:[],
      options:[]

    }
  },
  created(){
    this.currentSite = JSON.parse(localStorage.getItem('currentSite'));
    this.getDataOee();
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
    createChart(){
        this.options = {
          data: this.dadosGrafico,
          series: [
            {
              type: 'column',
              xKey: 'label',
              yKey: 'oee',
              fill: '#FF8D2E',
              stroke: '#FF8D2E',
              highlightStyle: {
                item: {
                  fill: '#FF8D2E',
                  stroke: '#FF8D2E',
                  strokeWidth: 0,
                },
                
              },
              tooltip: {
                renderer: function (params) {
                  return this.$cookies.get('userInfo').language == 'pt-BR' ? `
                  <div class="ag-chart-tooltip-content">
                  OEE Global: ${params.datum.oee}% <br>
                  Performance: ${params.datum.performance_component}% <br>
                  Disponibilidade: ${params.datum.available_component}% <br>
                  Qualidade: ${params.datum.quality_component}% <br>
                  </div>
                  ` : `
                  <div class="ag-chart-tooltip-content">
                  Overall OEE: ${params.datum.oee}% <br>
                  Performance: ${params.datum.performance_component}% <br>
                  Availability: ${params.datum.available_component}% <br>
                  Quality: ${params.datum.quality_component}% <br>
                  </div>`
                }
              }
            },
          ],
          legend: {
            enabled: false,
          },
          navigator: {
            height: 8,
            min: 0.1,
            max: 0.5,
            mask: {
              fill: '#FBEEE8',
              strokeWidth: 0.5,
              fillOpacity: 1,
            },
            minHandle: {
              stroke: '#CFC4BE',
              width: 12,
              height: 15,
              strokeWidth: 1,
            },
            maxHandle: {
              stroke: '#CFC4BE',
              width: 12,
              height: 15,
              strokeWidth: 1,
            },
          },
        }
    },
    getDataOee(){
      this.UPDATE_FLAG_SHOW_OVERLAY(true);
      this.$http.get(`/api/${this.$router.currentRoute.params.prefix}/order/oee?site_guid=${'A860E63D-522D-4B6F-9687-DFBEFAE3C38E' + this.urlResourcesLoading}`)
      .then(res => {
        this.oeeGlobal = res.data.oeeGlobal;
        this.resourcesList = res.data.oeeByResource;

        this.dadosGrafico = res.data.oeeByReferenceDate.map(item => {
          return {
            available_component: parseInt(item.available_component),
            label:  moment(item.reference_date).format("DD/MM"),
            oee: parseInt(item.oee),
            performance_component: parseInt(item.performance_component),
            quality_component: parseInt(item.quality_component)
          }
        });

      }).then(() => {

        this.createChart();
        this.keyControlRenderChart += 1;
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
      }).catch(error => {
        console.log(error)
      });
    },
    isEmpty(obj) { // for ES3 and older
      for(var prop in obj) {
          if(obj.hasOwnProperty(prop))
              return false;
      }
      return true;
    },
    clearFilters() {
      this.listagemFiltros = {
        workcenter: [],
        resource: [],
        date: {
          dataInicio: '',
          dataFim: ''
        }
      }
      this.verificaURL();
    },
    verificaURL(){
      this.urlResourcesLoading =  `&filterDays=7`

      if (this.listagemFiltros.workcenter.length > 0){
          
          this.urlResourcesLoading += `&workcenters_ids=${this.listagemFiltros.workcenter.map(item => item.id)}`
      }
      if (this.listagemFiltros.resource.length > 0){
          this.urlResourcesLoading += `&resources_ids=${this.listagemFiltros.resource.map(item => item.id)}`
      }
      if(this.listagemFiltros.date.dataInicio){
          this.urlResourcesLoading =  ''
          this.urlResourcesLoading += `&start_date=${this.listagemFiltros.date.dataInicio}`
      }
      if(this.listagemFiltros.date.dataFim){
          this.urlResourcesLoading =  ''
          this.urlResourcesLoading += `&end_date=${this.listagemFiltros.date.dataFim}`
      }
      this.getDataOee();
    },
    deleteFilteredItem(type = null, id = null) {
      if(type == 'workcenter') {
        this.listagemFiltros.workcenter.splice(
          this.listagemFiltros.workcenter.indexOf(
            this.listagemFiltros.workcenter.find((item) => item.id == id)), 1)
      } else if(type == 'resource') {
        this.listagemFiltros.resource.splice(
          this.listagemFiltros.resource.indexOf(
            this.listagemFiltros.resource.find((item) => item.id == id)), 1)
      } else {
        this.listagemFiltros.date.dataInicio = ''
        this.listagemFiltros.date.dataFim = ''
      }
      this.verificaURL()
    },
    getFormattedDate(){
      let formatedDate = '';

      if (this.$cookies.get('userInfo').language == 'pt-BR')
          formatedDate = `${moment(this.listagemFiltros.date.dataInicio).format('DD/MM/YY')} ${this.$t('Operator.Until')} ${moment(this.listagemFiltros.date.dataFim).format('DD/MM/YY')}`;
      else if (this.$cookies.get('userInfo').language == 'en-US')
        formatedDate = `${moment(this.listagemFiltros.date.dataInicio).format('MM/DD/YY')} ${this.$t('Operator.Until')} ${moment(this.listagemFiltros.date.dataFim).format('MM/DD/YY')}`;

      return formatedDate;
    }
  }
}
</script>

<style lang="scss">
.overlay-mensage{
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
}
.ag-chart-wrapper{
  canvas{
    height: 428px !important;
  }
}
.ag-chart-tooltip-content {
    background-color: #362F2B;
    color:#ffff;
    border-radius: 6px;
  }

.card-oee-dia{
  .card-body{
    padding: 16px 16px 4px 16px;
    .title{
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #4C4541;
      margin-bottom: 12px;
    }
  }
}
.top-card-oee{
  margin-bottom: 24px;
  .card-body{
    padding: 16px;
    height: 146px;
    .infos-card{
      .header-card{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .tooltip-oee-global{
          width: 288px;
        }
        .title{
          font-weight: 600;
          font-size: 18px;
          line-height: 26px;
          color: #4C4541;
        }
        .container-card-title{
          display: flex;
          align-items: center;
          .title{
            padding-right: 7px;
          }
        }
        .percent{
          font-weight: 600;
          font-size: 16px;
          line-height: 26px;
          color: #998F8A;
        }
      }
      .color-bar{
        display: flex;
        margin: 12px 0;
        .bar-active{
           background-color:#FF8D2E;
           height: 8px;
           border-top-left-radius: 5px;
           border-bottom-left-radius: 5px;
        }
        .bar{
          background-color:#F4EEE8;
          height: 8px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
        }

      }
      .indicators{
        display: flex;
        justify-content: space-between;
        .tilte-indicator{
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          color: #4C4541;
          margin-bottom: 4px;
        }
        .indicator{
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          color: #998F8A;
        }
      }
    }
  }
}
.card-oee-recursos{
  
  .card-body{
    padding: 16px 16px 4px 16px;
    .title{
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #4C4541;
      margin-bottom: 12px;
    }
    .infos-card{
      height: 429px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;               /* width of the entire scrollbar */
        border-radius: 50%;
      }

      &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #FBEEE8;        /* color of the tracking area */
        margin-bottom: 16px;
        margin-top: 6px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #CFC4BE;    /* color of the scroll thumb */
        border-radius: 20px;       /* roundness of the scroll thumb */
      }
      .resource-indicator{
        margin-bottom: 12px;
        padding: 12px;
        .title-percent{
          display: flex;
          justify-content: space-between;
          .resource-name{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #4C4541;
          }
          .resource-percent{
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #655D58;

          }
        }
        .color-bar{
          display: flex;
          margin-top: 4px;
          .bar-active{
              background-color:#FF8D2E;
              height: 4px;
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
          }
          .bar{
            background-color:#F4EEE8;
            height: 4px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }

        }
        &:hover{
          background: #FFEDE2;
          border-radius: 6px;
          .color-bar{
            .bar{
              background-color: #EBDFD3 !important;
            }
          }
        }
      }
    }
  }
}
.header-card .title {
  margin-bottom: 0 !important;
}

.percent-graph .oee{
  margin-top: -10px !important;
}

.tooltip-oee {
  .tooltip-inner{
    max-width:511px !important;
    padding: 0;
    .tooltip-top-card-oee{
      max-width:511px;
      min-width: none;
      border-radius: 6px;
      border: 1px solid #cfc4be;
      overflow: hidden;
      .title-tooltip {
          margin: 0;
          display: flex;
          justify-content: left;
          background-color: #ff8d2e !important;
          font-weight: 400;
          font-size: 16px;
          line-height: 22px;
          padding: 7.5px 16px;
          p{
            margin-bottom: 0;
          }
      }
      .info-tooltip {
          // width: 518px;
          text-align: left;
          background-color: #ffff;
          color: #4c4541;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          padding: 10px 16px;
          p {
            width:486px;
            margin-left: 8px;
            margin-bottom: 0;
            strong {
              margin-left: -8px;
            }
          }
      }
    }
  }
}
.resource-loading-filtro {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .limpar-filtros-btn {
    background: #974900;
    border-radius: 5px;
    white-space: nowrap;
    height: fit-content;
    border: 1px solid #974900;
    padding: 3px 14px;
    color: #fff;
    margin-right: 16px;
    font: 600 12px/20px initial;
    &:hover {
      background: #F7F3EF;
      color: #974900;
    }
  }
  .filtros-area {
    display: flex;
    overflow: auto;
    padding-right: 28px;
    margin-right: -28px;
    &::-webkit-scrollbar {
      height: 5px;               /* width of the entire scrollbar */
      border-radius: 50%;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #FBEEE8;        /* color of the tracking area */
      margin-right: 28px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #CFC4BE;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
    }
    .filtro-item {
      display: flex;
      font: 600 12px/20px initial;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
      padding: 6px 14px;
      margin: 0 5px;
      background-color: #FFDBC4;
      color: #974900;
      border-radius: 8px;
      svg {
        margin-left: 12px;
      }
      &:hover {
          cursor: pointer;
        }
    }
  }
}

@media (max-width: 575.98px) {
  .tooltip-oee {
    .tooltip-inner {
      .title-tooltip {
        width: 288px;
        p{
          width:256px;
          margin: 0;
          font-size: 14px;
          text-align: left;
        }
      }
      .info-tooltip {
        width: 288px;
        p{
          width: 248px;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .info-tooltip.quality{
        max-width: 256px;
      }
      .title-tooltip.quality{
          max-width: 256px;
      }
    }
  }
}

</style>

<style lang="scss" >
.percent-graph.oee{
  margin-bottom: 13px !important;
  margin: auto;
  position:relative;
  align-self: center;
  width:180px;
  height:90px;
  overflow: hidden;
  .cdc-text{
    margin-bottom: 45px;
    font-weight: 600;
    font-size: 22px !important;
    line-height: 30px;
    color: #4C4541;
  }
  
}
</style>
